<template>
  <div class="wine-list">
    <div class="category" :style="{color:category.textColour}">{{ category.name }}</div>
    <div class="slider" :class="{['-' + category.packagingType]: true}">
      <div class="slider__inner">
        <div class="splide" ref="slider">
          <div class="splide__track">
            <ul class="splide__list">
              <li v-for="wine in wineList" class="splide__slide" :key="wine.id">
                <router-link
                  :to="{ name: 'wine', params: {slug: wine.slug, categorySlug: category.slug} }"
                  class="wine"
                  :class="{['-' + category.packagingType]: true}"
                ><v-image
                  class="bottle"
                  :image="wine.bottle"
                /></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="controls">
        <button class="arrow -prev" @click="prev"></button>
        <button class="arrow -next" @click="next"></button>
      </div>
    </div>
    <div class="wines-wrapper">
      <div class="wines">
        <router-link
          v-for="wine in wineList"
          :key="wine.id"
          :to="{ name: 'wine', params: {slug: wine.slug, categorySlug: category.slug} }"
          class="wine"
          :class="{['-' + category.packagingType]: true}"
        ><v-image
          class="bottle"
          :image="wine.bottle"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import VImage from '@/components/Image.vue';
  import Splide from '@splidejs/splide';
  import '@splidejs/splide/dist/css/splide-core.min.css';

  export default {
    props: {
      category: Object
    },
    components: {
      VImage
    },
    data() {
      return {
        slider: null
      }
    },
    mounted() {
      this.fetchWineList(this.category.slug)
        .then(() => {
          this.$nextTick(() => {
            this.slider = new Splide(this.$refs.slider, {
              arrows: false,
              pagination: false,
              perPage: 3,
              padding: {
                right: '10vw',
                left: '10vw',
              },
              focus: 'center',
              rewind : true,
            }).mount();
          })
        });
    },
    destroyed() {
      this.slider.destroy();
      this.slider = null;
    },
    methods: {
      ...mapActions(['fetchWineList']),
      prev() {
        this.slider.go('<');
      },
      next() {
        this.slider.go('>');
      },
    },
    computed: {
      wineList() {
        return this.$store.getters.getWineList(this.category.slug);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .wine-list {
    background-color: #FBF8F1;
  }
  .category {
    font: bold #{vw-relative(16, 320)}/1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: .8em;
    padding: vw-relative(20 10, 320);
    @include breakpoint(medium) {
      padding: vw-relative(30 20);
      font-size: vw-relative(24);
    }
    @include breakpoint(xlarge) {
      font-size: 24px;
      padding: 50px 40px;
    }
  }
  .wines-wrapper {
    position: relative;
    padding-top: 28%;
    overflow: hidden;
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
  .wines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
  .wine {
    padding: 0 5vw;
    display: inline-block;
    transition: transform .2s;
    @include breakpoint(medium) {
      padding: 0;
      margin: 0 vw-relative(40);
      width: vw-relative(105);
      &.-box {
        width: vw-relative(250);
        display: inline-flex;
        align-items: flex-end;
        margin-bottom: vw-relative(-40);
      }
    }
    &:hover {
      transform: rotate(5deg);
    }
    &.-box {
      &:hover {
        transform: translateY(-5%);
      }
    }
  }
  .bottle {
    width: 100%;
    height: auto;
  }
  .slider {
    position: relative;
    padding-top: 50%;
    overflow: hidden;
    &.-box {
      padding-top: 30%;
    }
    @include breakpoint(medium) {
      display: none;
    }
    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .controls {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 2;
  }
  .arrow {
    position: absolute;
    pointer-events: all;
    width: 9.5vw;
    height: 9.5vw;
    display: block;
    position: absolute;
    top: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('~@/assets/images/arrow-grey.svg');
    &.-prev {
      left: 3vw;
      transform: translateY(-50%) rotate(90deg);
    }
    &.-next {
      right: 3vw;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
</style>
